<template>
  <div>
    <div class="content-title">
      <div class="title-left">
        <span>票据列表</span>
      </div>
      <div class="title-right">
        <el-button @click="handleBatch(1)" :disabled="!selections.length">生成凭证</el-button>
        <el-button @click="handleBatch(2)" :disabled="!selections.length">关联项目</el-button>
        <el-button :disabled="exportDisabled" @click="handleExportTableExcel">导出</el-button>
        <el-button type="primary" @click="handleDeleteTableData">删除</el-button>
      </div>
    </div>
    <div class="content-table">
      <el-table ref="incomeVatTable" :data="vatTableData" stripe border show-summary v-loading="status.tableLoading" :header-cell-style="handleHeaderCellStyle" :summary-method="handleGetSummaries" @selection-change="handleSelectionChange" style="width: 100%">
        <el-table-column type="selection" width="50" fixed="left" />
        <el-table-column type="index" label="序号" width="50" />
        <el-table-column prop="buyerName" label="组织名称" show-overflow-tooltip min-width="220" />
        <el-table-column prop="sellerName" label="销售方" show-overflow-tooltip min-width="220" />
        <el-table-column prop="invoiceType" label="发票类型" :formatter="handleTableValueFormat" width="180" />
        <el-table-column prop="invoiceCode" label="发票代码" width="150" />
        <el-table-column prop="invoiceNo" label="发票号码" width="120" />
        <el-table-column prop="eInvoiceNo" label="数电发票号码" :formatter="handleTableValueFormat"   width="180"  />   
        <el-table-column prop="taxAmount" label="税额" width="120" />
        <el-table-column prop="sumAmount" label="价税合计" width="120" />
        <el-table-column prop="invoiceTime" label="开票日期" :formatter="handleTableValueFormat" width="115" />
        <el-table-column prop="projectName" label="所属项目" width="120" />
        <el-table-column label="是否生成凭证" width="120">
          <template slot-scope="scope">
            {{ scope.row.voucherId ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="periodNo" label="会计期间" width="100" />
        <el-table-column label="关联凭证" width="120">
          <template slot-scope="scope"> {{ scope.row.voucherNo ? `记-${scope.row.voucherNo}` : '' }} </template>
        </el-table-column>
        <el-table-column prop="deductionDate" label="抵扣日期" :formatter="handleTableValueFormat" width="115" />

        <el-table-column prop="deductionStatus" label="抵扣状态" :formatter="handleTableValueFormat" width="115" />
        <el-table-column prop="signPeriod" label="所属征期" :formatter="handleTableValueFormat" width="115" />
        <el-table-column prop="source" label="发票来源" :formatter="handleTableValueFormat" width="80" />
        <el-table-column prop="receiveFromUpdate" label="更新来源" :formatter="handleTableValueFormat" width="80" />
        <el-table-column prop="updated" label="更新时间" width="180" />
        <el-table-column prop="operate" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="handleViewDetails(scope.row)">详情</el-button>
            <el-button type="text" :disabled="scope.row.invoiceFileId == 0" @click="handleViewImage(scope.row)"> 影像 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 票据详情弹窗 -->
    <vat-invoice-detail :show.sync="drawer" :invoice="invoice" />
    <!-- 票据影像弹窗 -->
    <el-image-viewer v-if="invoiceImageViewer" :url-list="invoiceImages" :on-close="handleImageViewerClose" />
    <!-- 导出弹窗 -->
    <el-dialog append-to-body title="提示" @before-close="handleExportExcelCancel" :visible.sync="exportVisible" width="30%">
      <div class="dialog-body">是否导出当前筛选出来的所有数据？</div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="handleExportExcelCancel">取 消</el-button>
        <el-button type="primary" @click="handleExportExcelConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除弹窗 -->
    <el-dialog append-to-body title="提示" @before-close="handleInvoicePoolDeleteCancel" :visible.sync="deleteVisible" width="30%">
      <div class="dialog-body">是否删除选中发票？</div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="handleInvoicePoolDeleteCancel">取 消</el-button>
        <el-button type="primary" @click="handleInvoicePoolDeleteConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 生成凭证 || 关联项目-->
    <el-dialog append-to-body :title="curdialogType == 1 ? '选择凭证模板' : '关联项目'" :visible.sync="chooseVisible" width="600px">
      <div class="dialog-body">
        <div class="voucher_tmpl_box" v-if="curdialogType == 1">
          <el-form class="cus_tmpl_form_box" :model="chooseVouParams" label-width="200px">
            <el-form-item label="凭证日期" prop="voucherDate">
              <el-date-picker v-model="chooseVouParams.voucherDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"> </el-date-picker>
            </el-form-item>
            <el-form-item label="凭证模板" prop="tmplId">
              <el-select v-model="chooseVouParams.tmplId" placeholder="请选择">
                <el-option v-for="item in vouTplList" :key="item.id" :label="item.tmplName" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否合并为一张凭证" prop="isMerge">
              <el-radio-group v-model="chooseVouParams.isMerge">
                <el-radio label="Y" :disabled="isDisRadio">是</el-radio>
                <el-radio label="N" :disabled="isDisRadio">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <div class="about_items_box" v-if="curdialogType == 2" style="height: 400px; width: 100%; overflow: auto">
          <div style="text-align: left"><el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width: 330px" suffix-icon="el-icon-search"> </el-input></div>
          <el-tree
            ref="tree"
            default-expand-all
            highlight-current
            :data="treeData"
            :props="{
              children: 'children',
              label: 'projectName'
            }"
            @node-click="handleNodeClick"
            :filter-node-method="filterNode"
          ></el-tree>
        </div>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="chooseVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDialog">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog append-to-body title="消息提示" :visible.sync="complateVible" width="30%">
      <div style="text-align: left; padding: 20px; font-size: 12px">{{ resComplateInfo.message }}</div>
      <div class="dialog-footer" slot="footer">
        <el-button v-if="resComplateInfo.success" @click="$router.push('/voucher/list')">查看凭证</el-button>
        <!-- <el-button @click="complateVible = false">取 消</el-button> -->
        <el-button type="primary" @click="complateVible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getInvoiceDetails, getInvoiceImage, deleteIncomeInvoiceData } from '@/service/invoice-pool.js';
import { tableStyle, changeMethods } from '@/util/mixins';
import VatInvoiceDetail from '@/views/invoice-pool/component/income/VatInvoiceDetail';
import InvoiceImage from '@/components/invoice/InvoiceImg';
import { exportInvoices } from '@/service/invoice-pool';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { voucherTmplList, voucherBuild, projectName, getProjectTree } from '@/service/voucher';

export default {
  name: 'VatTable',
  mixins: [tableStyle, changeMethods],
  components: { ElImageViewer, VatInvoiceDetail, InvoiceImage },
  props: ['vatTableData', 'status', 'search'],
  data() {
    return {
      filterText: '',
      isDisRadio: false,
      curdialogType: 1,
      chooseVisible: false,
      complateVible: false,
      resComplateInfo: {},
      chooseVouParams: {
        tmplId: '',
        isMerge: 'Y',
        voucherDate: this.$moment().format('yyyy-MM-DD'),
        invoiceIds: ''
      },
      aboutItemsParams: {
        invoiceIds: [],
        vouProjectId: '',
        projectName: ''
      },
      vouTplList: [],
      treeData: [],

      /* 票据影像数据区 */
      invoiceImageViewer: false,
      invoiceImages: [],
      // 表格选中信息
      selections: [],
      /* 导出按钮 */
      exportVisible: false,
      exportDisabled: false,
      // 删除按钮
      deleteVisible: false,
      // 发票详情信息
      drawer: false,
      invoice: {},
      // 发票影像信息
      invoiceImage: {
        url: '',
        visible: false
      },
      // 抵扣状态
      deductionStatus: [],
      invoiceSourceList: []
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    selections(val) {
      if (val.length == 1) {
        this.isDisRadio = true;
        this.chooseVouParams.isMerge = 'N';
        return;
      }
      this.isDisRadio = false;
      this.chooseVouParams.isMerge = 'Y';
    },
    vatTableData(value) {
      this.exportDisabled = value.length ? false : true;
    }
  },
  created() {
    this.handleInitDictionaries();
    this._voucherTmplList();
    this._getProjectTree();
  },
  methods: {
    _getProjectTree() {
      getProjectTree().then((res) => {
        if (res.success) {
          this.treeData = res.data;
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.projectName.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      // console.log(data);
      this.aboutItemsParams.vouProjectId = data.id;
      this.aboutItemsParams.projectName = data.projectName;
    },
    async _voucherTmplList() {
      const { data } = await voucherTmplList({ bizType: 'II' });
      this.vouTplList = data;
    },
    handleDialog() {
      if (this.curdialogType == 1) {
        if (!this.chooseVouParams.voucherDate) return this.toast('凭证日期不能为空！', 'warning');
        if (!this.chooseVouParams.tmplId) return this.toast('凭证模版不能为空！', 'warning');
        voucherBuild(this.chooseVouParams).then((res) => {
          this.chooseVisible = false;
          this.resComplateInfo = res;
          this.complateVible = true;
          this.$emit('refresh');
        });
      } else if (this.curdialogType == 2) {
        if (!this.aboutItemsParams.vouProjectId) return this.toast('请选择关联项目！', 'warning');
        projectName(this.aboutItemsParams).then((res) => {
          this.chooseVisible = false;
          res.success && this.toast('操作成功', 'success');
          this.$emit('refresh');
        });
      }
    },
    handleBatch(type) {
      this.curdialogType = type;
      this.chooseVisible = true;
      this.chooseVouParams.voucherDate = this.$moment().format('yyyy-MM-DD');
      const ids = this.selections.map((item) => item.id);
      this.chooseVouParams.invoiceIds = ids;
      this.aboutItemsParams.invoiceIds = ids;
      // if (type == 1) {
      // } else if (type == 2) {
      // }
    },
    /* 发票影像弹窗关闭 */
    handleImageViewerClose() {
      this.invoiceImageViewer = false;
      this.invoiceImages = [];
    },
    /* 初始化字典值 */
    handleInitDictionaries() {
      this.batchGetDicts({ types: ['deduction_status', 'invoice_source'] }).then(({ success, data }) => {
        if (success) {
          this.deductionStatus = data['deduction_status'];
          this.invoiceSourceList = data['invoice_source'];
        }
      });
    },
    /* 表格内容格式化 */
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '发票类型':
          return this.handleValueToLabel('InvoiceType', value);
        case '发票状态':
          return value;
        case '抵扣状态':
          if (value != null && value != '') {
            return this.getDictLabel(this.deductionStatus, value);
          }
          return '--';
        case '开票日期':
        case '抵扣日期':
          if (value != null) {
            return this.$moment(value).format('YYYY-MM-DD');
          }
          return '--';
        case '所属征期':
          if (value == null || value == '') {
            return '--';
          }
          return value;
        case '数电发票号码':
          if (value == '') {
            return '--';
          }
          return value;
        case '发票来源':
          return this.getDictLabel(this.invoiceSourceList, value);
        case '更新来源':
          return this.handleValueToLabel('InvoiceReceiveFrom', value);
        default:
          return value;
      }
    },
    /* 格式化合计行 */
    handleGetSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value)) && [7, 8].indexOf(index) > -1) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return Number((prev + curr).toFixed(2));
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' 元';
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    /* 选中表格信息 */
    handleSelectionChange(value) {
      this.selections = value;
    },
    /* 清空选中信息 */
    handleSelectionClear() {
      this.$refs.incomeVatTable.clearSelection();
    },
    /* 进项发票发票导出 */
    handleExportTableExcel() {
      this.exportVisible = true;
      this.exportDisabled = true;
    },
    /* 销项池导出取消 */
    handleExportExcelCancel() {
      this.exportVisible = false;
      this.exportDisabled = false;
    },
    /* 销项池导出确认 */
    async handleExportExcelConfirm() {
      this.exportVisible = false;
      this.exportDisabled = true;
      const rsp = await exportInvoices(this.search);
      this.downloadFile(rsp);
      this.exportDisabled = false;
    },
    /* 进项发票发票删除 */
    handleDeleteTableData() {
      if (this.selections.length == 0) return this.toast('请选择要删除的数据！', 'warning');
      this.deleteVisible = true;
    },
    /* 发票删除确认 */
    handleInvoicePoolDeleteConfirm() {
      let invoiceIds = this.selections.map((item) => item.id);
      deleteIncomeInvoiceData(invoiceIds).then((res) => {
        if (res.success) {
          this.toast('发票删除成功！', 'success');
          this.handleInvoicePoolDeleteCancel();
          this.$emit('refresh');
        }
      });
    },
    /* 发票删除取消 */
    handleInvoicePoolDeleteCancel() {
      this.deleteVisible = false;
      this.selections = [];
      this.$refs.incomeVatTable.clearSelection();
    },
    /* 进项发票发票详情 */
    handleViewDetails(invoice) {
      this.drawer = !this.drawer;
      getInvoiceDetails(invoice.id).then((res) => {
        if (res.success) {
          this.invoice = res.data;
          this.invoice.details.forEach((item) => (item.taxRate = Number(item.taxRate) * 100 + '%'));
          // 统计信息
          let sumAmount = this.invoice.details.map((item) => Number(item.amount)).reduce((sum, amount) => sum + amount, 0);
          let sumTaxAmount = this.invoice.details.map((item) => Number(item.taxAmount)).reduce((sum, taxAmount) => sum + taxAmount, 0);
          let total = {
            goodName: '合计',
            amount: Number(sumAmount).toFixed(2),
            taxAmount: Number(sumTaxAmount).toFixed(2)
          };
          this.invoice.details.push(total);
          let sum = Number(sumAmount + sumTaxAmount).toFixed(2);
          let sumBig = this.handleSmallToCapitalize(sum);
          let priceTax = {
            goodName: '价税合计（大写）',
            general: sumBig,
            specifications: '（小写）￥' + sum
          };
          this.invoice.details.push(priceTax);
        }
      });
    },
    /* 进项发票发票影像 */
    handleViewImage(invoice) {
      getInvoiceImage(invoice.id).then((res) => {
        if (res.success) {
          this.invoiceImageViewer = true;
          this.invoiceImages = [res.data];
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 22px;
}

.content-table {
  .el-button {
    padding: 0;
    margin-right: 10px;
  }
}

.dialog-body {
  display: flex;
  margin: 24px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  text-align: right;
  padding: 0 24px 24px;
}
</style>
<style lang="scss">
.cus_tmpl_form_box {
  text-align: left;
  .el-form-item {
    margin-bottom: 20px;
  }
  .el-form-item__label {
    width: 200px;
  }
}
</style>
